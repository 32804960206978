.appFooter {
  width: 100%;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #0c2d48;
  border-top: 3px solid white;

  h1 {
    color: white;
    padding: 20px;
  }
  .footerQuick {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;

    .quickInfo {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      .quickTitle {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        border-bottom: 2px solid white;
        padding-bottom: 5px;
        h1 {
          font-size: 20px;
          color: white;
        }
        .quickIcon {
          font-size: 30px;
          color: white;
          margin-right: 10px;
        }
      }
      .information {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-top: 5px;

        .quickIcon {
          font-size: 30px;
          color: white;
          margin-right: 10px;
        }
        p {
          font-size: 15px;
          color: white;
        }
      }
    }
  }
  .rights {
    width: 100%;
    padding-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      font-size: 15px;
      color: grey;
    }
  }
}
@media screen and (max-width: 600px) {
  .appFooter {
    h1 {
      text-align: center;
    }
  }
  .footerQuick {
    flex-direction: column !important;
  }
}
