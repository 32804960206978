.categoriesPage {
  width: 100%;
  height: 100%;
  padding-bottom: 80px;

  .headerTwo {
    font-size: 50px;
    font-weight: 400;
    color: #fff;
    margin: auto;
    padding-top: 30px;
    margin-bottom: 30px;
    width: 100%;
    text-align: center;
    // border-bottom: 2px solid #fff;
    padding: 15px;
    text-shadow: -2px 0px 2px rgb(0 0 0);
    backdrop-filter: blur(5px);

    &:before {
      content: "";
      position: absolute;
      left: 25%;
      bottom: 0;
      height: 2px;
      width: 50%; /* or 100px */
      border-bottom: 2px solid white;
    }
  }
  .categoriesBox {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    .categoryBox {
      width: 200px;
      height: 200px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
      padding: 10px;
      border-radius: 15px;
      box-shadow: rgba(0, 0, 0, 0.5) 0px 5px 15px;
      background-color: #fff;
      margin: 25px;
      cursor: pointer;
      transition: 350ms;
      img {
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        object-fit: cover;
        border-radius: 15px;
        filter: blur(2px);
        -webkit-filter: blur(2px);
      }
      p {
        height: 30%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        font-weight: 500;
        text-align: center;
        text-shadow: -2px 0px 4px rgba(0, 0, 0, 1);
        color: white;
        z-index: 1;
      }

      &:hover {
        scale: 110%;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .headerTwo {
    font-size: 40px !important;
  }
}
