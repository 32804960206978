@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* {
  margin: 0;
  box-sizing: border-box;
  font-family: "Montserrat" !important;
}
html {
  height: 100vh;
  width: 100vw;
}
body {
  width: 100vw;
  min-height: 100vh;
  overflow-x: hidden;
}
#root {
  width: 100%;
  height: 100%;
  padding: 60px 0px 0px 0px;
  background-color: #145da0;
}
.App {
  width: 100%;
  min-height: 100%;
  background-color: #145da0;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 5px 15px;
  padding-bottom: 80px;
}
