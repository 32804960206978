.homePage {
  width: 100%;
  height: 100%;
  padding-bottom: 80px;

  .headerOne {
    font-size: 50px;
    font-weight: 400;
    color: #fff;
    margin: auto;
    padding-top: 30px;
    margin-bottom: 30px;
    width: 100%;
    text-align: center;
    // border-bottom: 2px solid #fff;
    padding: 15px;
    text-shadow: -2px 0px 2px rgb(0 0 0);
    backdrop-filter: blur(5px);

    &:before {
      content: "";
      position: absolute;
      left: 25%;
      bottom: 0;
      height: 2px;
      width: 50%; /* or 100px */
      border-bottom: 2px solid white;
    }
  }

  .welcome {
    width: 100%;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 150px 30px 150px 30px;

    box-shadow: rgba(0, 0, 0, 0.5) 0px 5px 15px;

    .welcomeText {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 50%;
      padding: 50px;
      position: relative;
      animation-name: welcomeBox;
      animation-duration: 300ms;
      animation-timing-function: linear;
      animation-fill-mode: forwards;

      h1 {
        color: white;
        margin-bottom: 20px;
        font-size: 50px;
        font-weight: 400;
        text-shadow: rgba(0, 0, 0, 0.5) 0px 5px 15px;
        position: relative;
        animation-name: welcomeHeading;
        animation-duration: 500ms;
        animation-timing-function: linear;
        animation-fill-mode: forwards;
        animation-delay: 300ms;
        opacity: 0;
      }
      p {
        color: white;
        font-size: 30px;
        font-weight: 200;
        text-shadow: rgba(0, 0, 0, 0.5) 0px 5px 15px;
        position: relative;
        opacity: 0;
        animation-name: welcomeHeading;
        animation-duration: 500ms;
        animation-timing-function: linear;
        animation-fill-mode: forwards;
        animation-delay: 300ms;
      }
    }
  }
  .whyUs {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px;

    .reasons {
      margin-top: 30px;
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;

      p {
        font-size: 25px;
        font-weight: 200;
        color: white;
        background-color: #145da0;
        width: 40%;
        min-height: 300px;
        padding: 30px;
        box-shadow: rgba(0, 0, 0, 0.5) 0px 5px 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
      }
    }
    .reasonsTwo {
      margin-top: 30px;
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;

      .reasonCard {
        width: 200px;
        height: 200px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;

        .reasonIcon {
          font-size: 120px;
          color: white;
        }
        p {
          font-size: 20px;
          color: white;
          text-align: center;
        }
      }
    }
  }
  .container {
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 15px;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 5px 15px;
  }
  .categoriesBox {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    .categoryBox {
      width: 200px;
      height: 200px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
      padding: 10px;
      border-radius: 15px;
      box-shadow: rgba(0, 0, 0, 0.5) 0px 5px 15px;
      background-color: #fff;
      margin: 25px;
      cursor: pointer;
      transition: 350ms;
      img {
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        object-fit: cover;
        border-radius: 15px;
        filter: blur(2px);
        -webkit-filter: blur(2px);
      }
      p {
        height: 30%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        font-weight: 500;
        text-align: center;
        text-shadow: -2px 0px 4px rgba(0, 0, 0, 1);
        color: white;
        z-index: 1;
        backdrop-filter: blur(2px);
      }

      &:hover {
        scale: 110%;
      }
    }
  }
  .viewAll {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      padding: 15px 25px 15px 25px;
      outline: none;
      font-size: 20px;
      box-shadow: rgba(0, 0, 0, 0.5) 0px 5px 15px;
      cursor: pointer;
      border: none;

      &:hover {
        background-color: lighten(#145da0, 20%);
        color: white;
      }
    }
  }
  .partners {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, 200px);
    grid-template-rows: repeat(auto-fill, 200px);
    justify-content: center;
    padding: 10px;
    gap: 15px;
    align-items: center;
    .partnersBox {
      width: 100%;
      height: 100%;
      border-radius: 15px;
      box-shadow: rgba(0, 0, 0, 0.5) 0px 5px 15px;
      cursor: pointer;

      &:hover {
        scale: 110%;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 15px;
      }
    }
  }
}

@keyframes welcomeBox {
  0% {
    left: -2000px;
  }

  100% {
    left: 0;
  }
}
@keyframes welcomeHeading {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media screen and (max-width: 600px) {
  .welcomeText {
    width: 100% !important;

    padding: 10px !important;
    h1 {
      font-size: 40px !important;
    }
    p {
      font-size: 20px !important;
    }
  }
  .whyUs {
    padding: 20px !important;
  }
  .headerOne {
    font-size: 40px !important;
  }
  .reasons {
    flex-direction: column !important;

    p {
      font-size: 20px !important;
      width: 100% !important;
      margin-bottom: 15px !important;
    }
  }
  .reasonsTwo {
    flex-direction: column !important;
  }
}
@media screen and (max-width: 768px) {
  .welcomeText {
    width: 100% !important;

    padding: 10px !important;
    h1 {
      font-size: 40px !important;
    }
    p {
      font-size: 20px !important;
    }
  }
  .whyUs {
    padding: 20px !important;
  }
  .headerOne {
    font-size: 40px !important;
  }
  .reasons {
    p {
      font-size: 20px !important;
      min-height: 500px !important;
      margin-bottom: 15px !important;
    }
  }
}
@media screen and (max-width: 1200px) {
  .reasons {
    p {
      min-height: 500px !important;
    }
  }
}
