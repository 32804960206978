.banner {
  height: 80px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  background-color: white;
  z-index: 1000;
  box-shadow: rgba(0, 0, 0, 0.5) 0px -5px 15px;

  h1 {
    font-size: 40px;
    margin-right: 50px;
  }

  .bannerBox {
    width: auto;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-shrink: 0;

    h1 {
      font-size: 40px;
      margin: 0;
      margin-right: 50px;
      flex-shrink: 0;
      animation: scroll1 10s linear 0s infinite normal none running;
    }
  }
}

@keyframes scroll1 {
  0% {
    transform: translateX(5%);
  }
  100% {
    transform: translateX(-100%);
  }
}
