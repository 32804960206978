.productPage {
  width: 100%;
  height: 100%;

  .headerTwo {
    font-size: 50px;
    font-weight: 400;
    color: #fff;
    margin: auto;
    padding-top: 30px;
    margin-bottom: 30px;
    width: 100%;
    text-align: center;
    // border-bottom: 2px solid #fff;
    padding: 15px;
    text-shadow: -2px 0px 2px rgb(0 0 0);
    backdrop-filter: blur(5px);

    &:before {
      content: "";
      position: absolute;
      left: 25%;
      bottom: 0;
      height: 2px;
      width: 50%; /* or 100px */
      border-bottom: 2px solid white;
    }
  }
  .productContainer {
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 5px 15px;
  }

  .productInfo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: auto;
    flex-wrap: wrap;
    padding: 20px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    img {
      width: 500px;
      height: 500px;
      object-fit: cover;
      box-shadow: rgba(0, 0, 0, 0.5) 0px 5px 15px;
      margin: 30px;
    }

    table {
      border-collapse: collapse;
      margin: 30px;
      box-shadow: rgba(0, 0, 0, 0.5) 0px 5px 15px;
      flex-shrink: 0;
      // min-height: 500px;
      background-color: #2e8bc0;

      tr {
        margin: 20px;
      }
      th,
      td {
        padding: 20px;
        color: white;
        border: 2px solid white;
        text-align: center;
      }
      th {
        font-size: 20px;
      }
      td {
        font-size: 20px;
      }
    }
  }

  .findOut {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px;

    .contactWays {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
      flex-wrap: wrap;
    }
  }
  .categoriesBox {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    .categoryBox {
      width: 200px;
      height: 200px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      padding: 10px;
      border-radius: 15px;
      box-shadow: rgba(0, 0, 0, 0.5) 0px 5px 15px;

      background-color: #fff;
      margin: 25px;
      cursor: pointer;
      transition: 350ms;
      img {
        height: 70%;
        width: 100%;
        object-fit: cover;
        border-radius: 15px;
      }
      p {
        height: 30%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 15px;
        font-weight: 500;
        text-align: center;
      }

      &:hover {
        scale: 110%;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .headerTwo {
    font-size: 30px !important;
  }
  .productInfo {
    padding: 30px !important;

    img {
      width: 300px !important;
      height: 300px !important;
      margin: 0 !important;
    }
    .tableWrapper {
      width: auto;
      overflow-x: auto;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    table {
      margin: 0 !important;
      margin-top: 25px !important;
    }
  }
  form {
    width: 90%;
  }
}
