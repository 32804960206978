.adminPage {
  width: 100%;
  height: 100%;
}
.password {
  width: 100%;
  height: calc(100vh - 90px);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  .centralPasswordBox {
    width: 80%;
    height: 50%;
    background-color: #2e8bc0;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 5px 15px;

    h2 {
      font-size: 30px;
      color: white;
    }
    input[type="password"] {
      width: 80%;
      padding: 15px;
      outline: none;
      font-size: 20px;
      color: black;
      border: none;
    }
    button[type="submit"] {
      font-size: 20px;
      background-color: green;
      padding: 15px;
      border: none;
      outline: none;
      color: white;
      cursor: pointer;
      border: 3px solid white;
      border-radius: 100px;

      &:hover {
        background-color: lighten(green, 20%);
      }
    }
  }
  .blogForm {
    width: 80%;
    height: 70%;
    background-color: #2e8bc0;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 5px 15px;
    padding: 30px;
    h1 {
      font-size: 50px;
      color: white;
      width: 80%;
      margin-left: 10%;
      text-align: center;
      border-bottom: 1px solid white;
    }
    h2 {
      font-size: 30px;
      color: white;
    }
    input[type="text"] {
      width: 80%;
      padding: 15px;
      color: black;
      font-size: 30px;
      outline: none;
      border: none;
    }
    textarea {
      width: 80%;
      min-height: 100px;
      padding: 15px;
      color: black;
      font-size: 20px;
      resize: none;

      &:focus {
        outline: lime;
        border: 3px solid lime;
      }
    }
  }
}
