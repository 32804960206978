.navigation {
  width: 100vw;
  height: 60px;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  padding-left: 30px;
  padding-right: 30px;
  z-index: 2;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 5px 15px;
  border-bottom: 1px solid #0c2d48;
  .largeNav {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    img {
      height: 80%;
      cursor: pointer;
    }
    .right {
      width: auto;
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      p {
        color: #0c2d48;
        font-size: 20px;
        font-weight: 500;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        cursor: pointer;
        &:hover {
          background-color: #0c2d48;
          color: white;
        }
      }
    }
  }
  .smallNav {
    width: 100%;
    height: 100%;
    display: none;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    z-index: 1;

    .navIcon {
      font-size: 50px;
      color: #0c2d48;
      cursor: pointer;

      &:hover {
        color: lighten(#0c2d48, 20%);
      }
    }
  }
  .navDrawer {
    width: 300px;
    height: calc(100vh - 60px);
    position: fixed;
    top: 60px;
    display: none;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    transition: 350ms;
    background-color: #fff;
    border-right: 1px solid #0c2d48;
    z-index: 2;

    p {
      width: 100%;
      padding: 20px;
      font-size: 20px;
      color: #0c2d48;
      cursor: pointer;

      border-bottom: 1px solid #0c2d48;

      &:hover {
        background-color: #0c2d48;
        color: white;
      }
    }
  }
}

/* Extra small devices (phones, 600px and down) */
@media screen and (max-width: 768px) {
  .largeNav {
    display: none !important;
  }
  .smallNav {
    display: flex !important;
  }
  .navDrawer {
    display: flex !important;
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}
