form {
  margin-top: 30px;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: auto;
  background-color: #2e8bc0;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 5px 15px;
  border-radius: 10px;
  padding: 40px;
  label {
    font-size: 20px;
    color: white;
    width: 100%;
    margin-bottom: 15px;
  }
  input {
    width: 100%;
    padding: 10px;
    background-color: transparent;
    outline: none;
    border: none;
    border-bottom: 1px solid white;
    color: white;
    margin-bottom: 15px;
    font-size: 20px;
    &::placeholder {
      font-size: 20px;
      color: white;
    }
    &:focus {
      border-bottom: 1px solid green;
    }
  }
  textarea {
    width: 100%;
    padding: 10px;
    background-color: transparent;
    outline: none;
    border: 1px solid white;
    font-size: 20px;
    color: white;
    resize: none;
    min-height: 100px;
    &::placeholder {
      font-size: 20px;
      color: white;
    }
  }
  button {
    padding: 10px;
    outline: none;
    border: none;
    font-size: 20px;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 5px 15px;
    cursor: pointer;
    margin-top: 20px;
    border-radius: 10px;

    &:hover {
      background-color: darken(white, 10%);
    }
  }
  p {
    padding: 20px;
    font-size: 20px;
    color: green;
    font-weight: 600;
  }
}
