.contactPage {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  h1 {
    color: #fff;
    font-size: 50px;
    width: 100%;
    height: 10%;
    text-align: center;
  }
}

.contactBox {
  width: 100%;
  height: 90%;
}
.contactMap {
  width: 100%;
  height: 500px;
  margin: auto;
  box-shadow: 0px -10px 20px -10px rgba(0, 0, 0, 0.5);
  margin-top: 30px;

  iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
}
.contactInfo {
  width: 100%;
  height: 75%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;

  .contactInfoBox {
    width: 300px;
    height: 300px;
    background-color: #2e8bc0;
    margin: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 25px;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 5px 15px;
    transition: 350ms;
    text-align: center;
    border-radius: 10px;
    &:hover {
      scale: 110%;
    }

    h2 {
      color: #fff;
      font-size: 30px;
      height: 20%;
      width: 100%;
    }

    .contactInfoText {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 50%;
    }

    a {
      color: #fff;
      text-decoration: none;
      font-size: 20px;
      &:hover {
        color: #07689f;
      }
    }
    .contactLogo {
      width: 100%;
      height: 30%;
    }
    .contactIcon {
      font-size: 50px;
      color: #fff;
    }
  }
}
